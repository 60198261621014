// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-templates-changelog-js": () => import("./../../../src/templates/changelog.js" /* webpackChunkName: "component---src-templates-changelog-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-implementations-js": () => import("./../../../src/templates/implementations.js" /* webpackChunkName: "component---src-templates-implementations-js" */),
  "component---src-templates-implementer-incomplete-js": () => import("./../../../src/templates/implementer-incomplete.js" /* webpackChunkName: "component---src-templates-implementer-incomplete-js" */),
  "component---src-templates-implementer-js": () => import("./../../../src/templates/implementer.js" /* webpackChunkName: "component---src-templates-implementer-js" */),
  "component---src-templates-rule-js": () => import("./../../../src/templates/rule.js" /* webpackChunkName: "component---src-templates-rule-js" */)
}

